<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.regions')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected"
          @click="navigateToRegionStores"
        >
          <v-icon>mdi-store</v-icon>
          <span class="text--secondary">{{ $t("app.route.stores") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          color="green"
          @click="$$router.push({ name: 'admin.region', params: { regionId: 'new', back: { name: $route.name } } })"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected"
          @click="editItem()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="red"
          v-if="$hasRole('SUPER-ADMIN') || $hasRole('ADMIN')"
          :disabled="!selected"
          @click="confirmDelete()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(84)">
        <v-data-table
          dense
          :headers="headers"
          :items="getRegions"
          item-key="id"
          :search="search"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <template #item.name="{ item }">
            <v-avatar size="32" class="my-1 mr-1" style="border: 1px solid #eee;">
              <v-img v-if="!!item.countryShort" :src="`https://flagcdn.com/${item.countryShort}.svg`"></v-img>
              <!-- <v-img v-if="!!item.countryShort" alt="Logo" :src="getServerImage(item.logo)"></v-img> -->
            </v-avatar>
            <span>{{ item.name }}</span>
          </template>

          <template #item.active="{ item }">
            <v-icon
              class="mr-4"
              :color="!!item.active ? 'green' : 'orange'"
              >{{
                !!item.active
                  ? "mdi-check"
                  : "mdi-checkbox-blank-outline"
              }}</v-icon
            >
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "AdminRegions",
  components: { ViewContainer, Toolbar },
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 70 },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("region.currency"), value: "currency" },
        { text: this.$t("general.active"), value: "active" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.clearSelectedRegion();
    this.loadRegions().then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("regions", ["getRegions"]),
    ...mapState({
      selected(state) {
        return state.regions.selected;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
  },
  methods: {
    ...mapActions("regions", [
      "loadRegions",
      "deleteRegion",
      "setSelectedRegion",
      "clearSelectedRegion",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]), //, "setBreadcrumbs"

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedRegion(event.item);
      } else if(event.id && this.selected?.id !== event.id) {
        this.setSelectedRegion(event);
      } else {
        this.clearSelectedRegion();
      }
    },
    editItem() {
      if (this.selected) {
        this.$$router.push({
          name: "admin.region",
          params: { regionId: this.selected.id, back: { name: this.$route.name } },
        });
      }
    },
    confirmDelete() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} region?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteRegion(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    navigateToRegionStores() {
      if(this.selected) {
        this.$$router.push({ name: "admin.region.stores", params: { regionId: this.selected.id, back: { name: this.$route.name } },  });
      }
    },
  },
};
</script>
